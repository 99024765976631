import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Button, TextField } from "@material-ui/core"
import { API_ROOT, ENV } from "gatsby-env-variables"
import axios from "axios"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Link, navigate } from "gatsby"
import LOGO from "../images/invoke_logo3.png"
import { Snackbar as MaterialSnackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"

const ResetPassword = props => {
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [expired, setExpired] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState("")
  const [severity, setSeverity] = useState("success")
  const uuid = props.location.search.split("=")
  useEffect(() => {
    if (props.location.search !== "") {
      axios
        .get(
          `${API_ROOT}${ENV}/external-user/check-link-status?uuid=${uuid[1]}`
        )
        .then(response => {
          if (response.data.statusCode === 500) {
            setLoading(false)
            setExpired(true)
          } else {
            setLoading(false)
            setExpired(false)
          }
        })
    }
  }, [])

  const onSubmit = e => {
    e.preventDefault()
    if (validation()) {
      setLoading(true)
      axios
        .post(`${API_ROOT}${ENV}/external-user/set-new-password`, {
          newPassword: password,
          confirmPassword: confirmPassword,
          uuid: uuid[1],
        })
        .then(response => {
          if (response.data.statusCode === 200) {
            setLoading(false)
            navigate("/login")
          } else {
            setLoading(false)
            setError(true)
            setSeverity("error")
            setMessage(response.data.devMessage)
          }
        })
    }
  }

  const onClickCloseSnackbar = () => {
    setError(false)
  }

  const validation = () => {
    if (password.trim() === "" || confirmPassword.trim() === "") {
      setError(true)
      setSeverity("error")
      setMessage("All fields are required!")
      return false
    } else if (password !== confirmPassword) {
      setError(true)
      setSeverity("error")
      setMessage("Passwords do not match!")
      return false
    } else {
      return true
    }
  }
  return (
    <Layout login>
      <Seo title="Reset Password - INVOKE by FREE THE WORK" />

      <div className="bg login-page">
        <div className="container">
          <div className="top">
            <Link to="/">
              <img src={LOGO} alt="INVOKE" />
            </Link>
            <p>Reset password</p>
          </div>
          {expired ? (
            <div className="top">
              <h1>Link Expired</h1>
            </div>
          ) : (
            <form onSubmit={onSubmit}>
              <div className="form-wrapper">
                <div className="flex">
                  <TextField
                    type="password"
                    fullWidth
                    placeholder="New Password"
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
                <div className="flex">
                  <TextField
                    type="password"
                    fullWidth
                    placeholder="Confirm Password"
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div className="flex actions reset">
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    className="reset"
                  >
                    {loading ? (
                      <CircularProgress size="24px" color="white" />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      <MaterialSnackbar
        open={error}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={onClickCloseSnackbar}
        disableWindowBlurListener
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={onClickCloseSnackbar}
          severity={severity}
        >
          {message}
        </MuiAlert>
      </MaterialSnackbar>
    </Layout>
  )
}

export default ResetPassword
